<template>
  <admin-base ref="base">
    <div slot="title">
      Orders
    </div>
    <div class="card">
      <div class="card-body">
        <b-field label="Store">
          <b-form-select v-model="currentStore" placeholder="Select a store" @change="loadData">
            <option
              v-for="store in stores"
              :key="store.id"
              :value="store.id"
            >
              {{ store.name }}
            </option>
          </b-form-select>
        </b-field>

        <advanced-table
          :columns="columns"
          :values="orders"
          :filtered-size="filteredSize"
          :on-start-load-data="onStartLoadData"
          :on-loaded-data="onLoadedData"
          :on-select-item="onSelectData"
          :paginated="true"
          :show-filter="true"
          :filter-case-sensitive="false"
          empty-message="Nenhum pedido encontrado"
        />
        <div class="col-sm-6 col-md-6 col-lg-6">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="$router.push({ name: 'admin-orders-new' })">
            Create
          </b-button>
        </div>
      </div>
    </div>
  </admin-base>
</template>

<script>
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

import * as bootstrapVue from 'bootstrap-vue'

import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import AdminBase from '@/views/admin/AdminBase.vue'

const AdvancedTable = () => import('@core/components/AdvancedTableComponent.vue')

export default {
  components: {
    AdminBase,
    AdvancedTable,
    BButton: bootstrapVue.BButton,
    BFormSelect: bootstrapVue.BFormSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const stores = ref([])
    const orders = ref([])

    return {
      currentStore: {},
      stores,
      orders,
    }
  },
  data() {
    return {
      columns: [
        {
            title: 'ID',
            name: 'id',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-40px',
            filtered: false,
        },
        {
            title: 'Código',
            name: 'code',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-40px',
        },
        {
            title: 'Comprador',
            name: 'eaterName',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-40px',
        },
        {
            title: 'Status',
            name: 'status',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
      ],
      sortColunms: [],
      filteredSize: 0,
      onStartLoadData() {
      },
      onLoadedData(data) {
          const self = this.parent
          self.detailObj = data.Data

          self.registraNavecaoComContexto()
          self.filteredSize = data.total

          self.loadCheckboxConteudo()

          self.showResults = true
          self.verifyDate()
      },
      onSelectData(event, data) {
        this.$router.push({ name: 'admin-orders-edit', params: { id: data.id } })
      },
    }
  },
  mounted() {
    this.loadStores()
  },
  created() {
  },
  methods: {
    loadStores() {
      const self = this
      this.$refs.base.showLoading()

      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/list?page=1`, useJwt.getHeader())
      .then(response => {
        self.stores = response.data.stores.data ?? []
        self.loadData()
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(error.response.data.message)
        this.errored = true
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    loadData() {
      const self = this
      if (self.currentStore == null) return
      this.$refs.base.showLoading()

      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/stores/${this.currentStore}/orders/list?page=1`, useJwt.getHeader())
      .then(response => {
        self.orders = response.data.orders.data ?? []
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(error.response.data.message)
        this.errored = true
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
  },
}
</script>
